import { BrowserRouter, Route, Switch } from "react-router-dom";

import { AccountPage } from "pages/account";
import { KycPage } from "./pages/kyc";
import { LoginPage } from "./pages/login";
import { Payments as PaymentsPage } from "pages/Payments/Payments";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "redux/store";

function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/payments">
              <PaymentsPage />
            </Route>
            <Route path="/account">
              <AccountPage />
            </Route>
            <Route path="/">
              <KycPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
