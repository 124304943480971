import { Link, useHistory, useLocation } from "react-router-dom";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { deleteAccessToken } from "scripts/jwt/deleteJwt";
import logo from "assets/images/sidenav/dark-logo.svg";

export const SideNav = () => {
  const router = useHistory();
  const { pathname } = useLocation();

  const logout = () => {
    deleteAccessToken();
    router.push("/login");
  };

  return (
    <div className="h-screen border shadow bg-gray-50">
      <img src={logo} alt="Logo" width={50} className="py-3 mx-auto" />

      <List component="nav">
        <Link to="/">
          <ListItem button selected={pathname === "/"}>
            <ListItemText primary="KYC" />
          </ListItem>
        </Link>
        <Link to="/account">
          <ListItem button selected={pathname === "/account"}>
            <ListItemText primary="Manage Account" />
          </ListItem>
        </Link>
        <Link to="/payments">
          <ListItem button selected={pathname === "/payments"}>
            <ListItemText primary="Payments" />
          </ListItem>
        </Link>
        <ListItem button onClick={logout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};
